@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
/* add css module styles here (optional) */

._1Lxpd {
  width: 200px;
  height: 50px;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

._30Ipg {
  background: rgb(231, 231, 231);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
  border-radius: 5px;
  display: flex;
  height: 80%;
  width: 100px;
  position: relative;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
}

._1KMXW {
  position: relative;
  margin-top: 18px;
  transition: all 0.5s;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

._2-_Xn {
  padding: 10px;
  height: 200px;
  overflow-y: scroll;
}

._3LhE5 {
  margin: 10px;
  padding-top: 10px;
  position: relative;
}

._3_9HH {
  outline: none;
  border: none;
  border-radius: 3px;
  background: #f0f0f0;
  padding: 5px;
  width: 94%;
}

._3_9HH:-ms-input-placeholder {
  font-family: 'Poppins';
  font-size: 13px;
  color: rgb(168, 168, 168);
}

._3_9HH::-webkit-input-placeholder {
  font-family: 'Poppins';
  font-size: 13px;
  color: rgb(168, 168, 168);
}

._3_9HH::placeholder {
  font-family: 'Poppins';
  font-size: 13px;
  color: rgb(168, 168, 168);
}

/* width */
._2-_Xn::-webkit-scrollbar {
  width: 8px;
}

/* Track */
._2-_Xn::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
._2-_Xn::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
._2-_Xn::-webkit-scrollbar-thumb:hover {
  background: #696969;
}


._34ETa {
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
}

._14Kkd {
  margin-left: 15px;
  margin-top: -2px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #484848;
}

._3Dx_t {
  font-size: 15px;
  font-weight: 500;
  color: #484848;
  margin-left: 15px;
}

._2vjgV {
  height: 15px;
  width: 22px;
  border-radius: 2px;
}
